import React, { lazy } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@soy-libs/uikit2'
import BigNumber from 'bignumber.js'
import { CookiesProvider } from 'react-cookie';
import useEagerConnect from 'hooks/useEagerConnect'
import RouteChangeTracker from 'RouteChangeTracker'
import { ToastListener } from 'contexts/ToastsContext'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import PageLoader from './components/PageLoader'
import GlobalStyle from './style/Global'
import history from './routerHistory'

import 'aos/dist/aos.css'

const Home = lazy(() => import('./pages/Home'))
// const About = lazy(() => import('./pages/About'));
const AboutNft = lazy(() => import('./pages/AboutNft'))
const HowToBuyNft = lazy(() => import('./pages/HowToBuy'))
const NotFoundNft = lazy(() => import('./pages/NotFound'))
const Profile = lazy(() => import('./pages/Profile'))
const Bids = lazy(() => import('./pages/Mybids'))
const FAQ = lazy(() => import('./pages/FAQ'))
const Roadmap = lazy(() => import('./pages/Roadmap'))
const Marketplace = lazy(() => import('./pages/Marketplace'))
const Competition = lazy(() => import('./pages/Competition'))
const NftDetails = lazy(() => import('./pages/NftDetails'))


BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  useEagerConnect()

  return (
    <CookiesProvider>
      <Router history={history}>
        <ResetCSS />
        <GlobalStyle />
        <RouteChangeTracker />
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/about-nft">
              <AboutNft />
            </Route>
            <Route path="/how-to-buy-nft">
              <HowToBuyNft />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/mybids">
              <Bids />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/roadmap">
              <Roadmap />
            </Route>
            <Route path="/marketplace">
              <Marketplace />
            </Route>
            <Route path="/competition">
              <Competition />
            </Route>
            <Route exact path="/nft-details/:NftId">
              <NftDetails />
            </Route>
            <Route component={NotFoundNft} />
            
          </Switch>
        </SuspenseWithChunkError>
        <ToastListener />
      </Router>
    </CookiesProvider>
  )
}

export default React.memo(App)
