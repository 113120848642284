import React from 'react';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'
import styled from 'styled-components';
import { ToastsProvider } from 'contexts/ToastsContext';
import { getLibrary } from './utils/web3React'
import store from './store';
import { ThemeContextProvider } from './ThemeContext'

const SpecialModalBackground = styled(BaseModalBackground)`
  align-items: flex-start !important;
  padding-bottom: 20px !important;
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
`
const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store= {store} >
        <ToastsProvider>
          <ThemeContextProvider>
            <ModalProvider  backgroundComponent={SpecialModalBackground}>
              {children}
            </ModalProvider>
          </ThemeContextProvider>
        </ToastsProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers;
