import { InjectedConnector } from '@web3-react/injected-connector'
import { ConnectorNames } from '@soy-libs/uikit2'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import getNodeUrl from 'utils/getRpcUrl'

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
// const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()

const injected = new InjectedConnector({ supportedChainIds: [820, 20729] })
const walletconnect = new WalletConnectConnector({
    rpc: { [chainId]: rpcUrl },
    bridge: 'https://soyfinance.bridge.walletconnect.org/',
    qrcode: true,
})
const bscConnector = new BscConnector({ supportedChainIds: [chainId] })

export const connectorLocalStorageKey = "connectorIdArtefinNft";

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
    [ConnectorNames.BSC]: bscConnector,
}

export const BASE_EXP_URLS = {
    820: "https://explorer.callisto.network/",
    20729: "https://testnet-explorer.callisto.network/"
}

export const EMAIL_ADDRESS = 'nft@artefin.cz'

export const API_BASE_URL = {
    820: 'https://nftx-clo.deta.dev/nft/',
    20729: 'https://nftx-testnet.deta.dev/nft/'
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const CATEGORIES = {
    'original': 'Original',
    'gold': 'Emerald',
    'silver': 'Sapphire',
    'bronze': 'Purple',
}

export const DEFAULT_GAS_LIMIT = 3000000
