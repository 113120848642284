export const Theme = {
    colors: {
        bg: "#EDEDED",
        primary: "#FFFFFF",
        secondary: "#E45C31",
        third: "#262825",
        
        fourth: "#26c973",
        fifth: "#58b4fd",
        white: "#FFF",
        black: "#000",
        opacity2th: 'rgba(6, 63, 89, .2)',
        footerbk: "#21325b",

        lightgrey: "#fafafa",
        grey: "#d5d5d5",
        primary2: "#676E51",
        blogback: "#F9F8F8",
        transprimary: 'rgba(52,179,124, .2)',

        failure: "#ED4B9E",
        success: "#31D0AA",
        warning: "#FFB237",
        info: "#17a2b8",
    },
    fonts: {
        textBold:  'Montserrat',
        medium: "Montserrat",
        text: 'Montserrat',
        btnSize: `13px`,
        greyText: `#BCBCBC`,
    }
}
