import { BASE_EXP_URLS } from '../constants'
import { nodes } from './getRpcUrl'
// Set of helper functions to facilitate wallet setup
const BASE_URL = "https://app.soy.finance"
/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = window.ethereum

  if (provider) {
    const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
      try {
        
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: `Callisto Network`,
              nativeCurrency: {
                name: `Callisto`,
                symbol: `CLO`,
                decimals: 18,
              },
              rpcUrls: nodes,
              blockExplorerUrls: [BASE_EXP_URLS[chainId]],
            },
          ],
        })
        return true
      } catch (error) {
        console.error('Failed to setup the network in Metamask:', error)
        return false
      }
  } else {
    console.error("Can't setup the Callisto network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${BASE_URL}/images/${tokenAddress}.png`,
      },
    },
  })

  return tokenAdded
}