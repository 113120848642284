import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #FFFFFF;
        img {
            height: auto;
            max-width: 100%;
        }
    }
    .connectBtn{
        border: solid 1px black;
        font-size: 13px;
        font-weight: 600;
        background-color: white;
        border-radius: 0;
        padding: 8px 15px;
        box-shadow: none;
        display: inline-block;
        letter-spacing: normal;
        height: inherit;
        color: black;
    }
    * {
        font-family: 'Montserrat', sans-serif !important;
    }
`
export default GlobalStyle;
