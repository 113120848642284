import React from 'react';
import ReactDOM from 'react-dom';
import { ResetCSS } from '@soy-libs/uikit2';
import ReactGA from 'react-ga'
import App from 'App';
import GlobalStyle from './style/Global';
import Providers from './Providers';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'inter-ui';
import './App.scss';

const TRACKING_ID = process.env.REACT_APP_GTAG
ReactGA.initialize(TRACKING_ID)

ReactDOM.render(

        <Providers>
            <ResetCSS />
            <GlobalStyle />
            <App />
        </Providers>
    ,
    document.getElementById('root')
);